var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "区域价格设置",
        visible: _vm.dialogVisible,
        width: "800px",
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "show-close": false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            "max-height": "50vh",
            "overflow-y": "auto",
            padding: "15px 30px",
            "background-color": "#f6f6f6"
          }
        },
        [
          _vm._l(_vm.gradeList, function(item, index) {
            return _c(
              "el-row",
              {
                staticStyle: {
                  display: "flex",
                  "flex-direction": "row",
                  "align-items": "center",
                  "margin-top": "10px"
                }
              },
              [
                _c("div", [_vm._v(_vm._s(index + 1 + "、"))]),
                _c("div", { staticStyle: { width: "100px" } }, [
                  _vm._v(_vm._s(item.name))
                ]),
                _c(
                  "el-input",
                  {
                    staticStyle: { width: "300px", "margin-left": "20px" },
                    attrs: { placeholder: "请输入对应的产品价格" },
                    model: {
                      value: item.price,
                      callback: function($$v) {
                        _vm.$set(item, "price", $$v)
                      },
                      expression: "item.price"
                    }
                  },
                  [
                    _c("template", { slot: "prepend" }, [
                      _vm._v("对应的产品价格")
                    ])
                  ],
                  2
                )
              ],
              1
            )
          }),
          _c(
            "el-button",
            {
              staticStyle: { "margin-top": "20px" },
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.clickBatch }
            },
            [_vm._v("跟主价格一致")]
          )
        ],
        2
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.dialogVisible = false
                }
              }
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.clickSubmit } },
            [_vm._v("提交")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }