import "core-js/modules/es.object.to-string";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { memberTakeApply, tonglianMoneyQuery } from '../../../../api/apis';
export default {
  data: function data() {
    return {
      dialogVisible: false,
      form: {
        id: '',
        company_name: '',
        amount: 0,
        allAmount: 0
      },
      rules: {
        amount: [{
          require: true,
          validator: this.$tempModel.rules.money.validator,
          trigger: 'blur'
        }]
      }
    };
  },
  mounted: function mounted() {},
  methods: {
    show: function show(row, finish) {
      var _this = this;

      this.dialogVisible = true;
      this.form.id = row.id;
      this.form.company_name = row.company_name;
      this.finish = finish;
      tonglianMoneyQuery({
        id: row.id
      }).then(function _callee(res) {
        return regeneratorRuntime.async(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _this.form.allAmount = res.allAmount;

              case 1:
              case "end":
                return _context.stop();
            }
          }
        });
      });
    },
    clickSubmit: function clickSubmit() {
      var _this2 = this;

      this.$refs['form'].validate(function (valid) {
        if (valid) {
          _this2.$g.fun.startLoading();

          memberTakeApply(_this2.form).then(function _callee2(res) {
            return regeneratorRuntime.async(function _callee2$(_context2) {
              while (1) {
                switch (_context2.prev = _context2.next) {
                  case 0:
                    _this2.$g.fun.endLoading();

                    _this2.$message({
                      message: '操作成功',
                      type: 'success'
                    });

                    _this2.dialogVisible = false;

                    if (_this2.finish) {
                      _this2.finish();
                    }

                  case 4:
                  case "end":
                    return _context2.stop();
                }
              }
            });
          }, function () {
            _this2.$g.fun.endLoading();
          });
        }
      });
    }
  }
};