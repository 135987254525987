var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "财务审核",
        visible: _vm.dialogVisible,
        width: "50%",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "show-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            "max-height": "50vh",
            "overflow-y": "auto",
            padding: "15px 30px",
            "background-color": "#f6f6f6"
          }
        },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form, "label-width": "120px" } },
            [
              _c("el-form-item", {
                attrs: { label: "订单号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-input", { attrs: { value: _vm.form.order_id } })
                      ]
                    }
                  }
                ])
              }),
              _c("el-form-item", {
                attrs: { label: "订单金额" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-input", {
                          attrs: { value: _vm.form.total_price }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-form-item", {
                attrs: { label: "审核状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择" },
                            model: {
                              value: _vm.form.auth_status,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "auth_status", $$v)
                              },
                              expression: "form.auth_status"
                            }
                          },
                          _vm._l(_vm.options, function(item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value }
                            })
                          }),
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-form-item", {
                attrs: { label: "备注" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-input", {
                          model: {
                            value: _vm.form.remark,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "remark", $$v)
                            },
                            expression: "form.remark"
                          }
                        })
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c("el-button", { on: { click: _vm.clickCancel } }, [
            _vm._v(_vm._s("取消"))
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.clickSubmit } },
            [_vm._v(_vm._s("确定"))]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }