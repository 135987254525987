import "core-js/modules/es.object.to-string";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { orderAuthStatus } from "@/api/apis";
export default {
  data: function data() {
    return {
      dialogVisible: false,
      form: {
        order_id: '',
        total_price: '',
        auth_status: 1,
        remark: ''
      },
      options: [{
        value: 1,
        label: '通过'
      }, {
        value: -1,
        label: '驳回'
      }]
    };
  },
  created: function created() {},
  methods: {
    show: function show(row, finish) {
      this.dialogVisible = true;
      this.finish = finish;
      this.form.order_id = row.order_id;
      this.form.total_price = row.total_price;
    },
    clear: function clear() {
      this.dialogVisible = false;
      this.finish = null;
    },
    clickCancel: function clickCancel() {
      this.clear();
    },
    clickSubmit: function clickSubmit() {
      var _this = this;

      var params = {
        order_id: this.form.order_id,
        auth_remark: this.form.remark,
        auth_status: this.form.auth_status
      };
      this.$g.fun.startLoading();
      orderAuthStatus(params).then(function _callee(res) {
        return regeneratorRuntime.async(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _this.$g.fun.endLoading();

                if (_this.finish) {
                  _this.finish(_this.form);
                }

                _this.clear();

              case 3:
              case "end":
                return _context.stop();
            }
          }
        });
      }, function () {
        _this.$g.fun.endLoading();
      });
    }
  }
};