import "core-js/modules/es.symbol";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.object.get-own-property-descriptor";
import "core-js/modules/es.object.get-own-property-descriptors";
import "core-js/modules/es.object.keys";
import "core-js/modules/web.dom-collections.for-each";
import _typeof from "/Users/suifeng/Desktop/projects/2024/fahuo/thFaHuoAdmin/node_modules/@babel/runtime/helpers/esm/typeof";
import _defineProperty from "/Users/suifeng/Desktop/projects/2024/fahuo/thFaHuoAdmin/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import store from '@/store';
import util from '@/libs/util';
export default {
  install: function install(Vue, options) {
    // 快速打印 log
    Vue.prototype.$log = _objectSpread({}, util.log, {
      push: function push(data) {
        if (typeof data === 'string') {
          // 如果传递来的数据是字符串
          // 赋值给 message 字段
          // 为了方便使用
          // eg: this.$log.push('foo text')
          store.dispatch('d2admin/log/push', {
            message: data
          });
        } else if (_typeof(data) === 'object') {
          // 如果传递来的数据是对象
          store.dispatch('d2admin/log/push', data);
        }
      }
    });
  }
};