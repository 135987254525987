import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.last-index-of";
import "core-js/modules/es.function.name";
import "core-js/modules/es.parse-int";
import "core-js/modules/es.string.replace";
import "core-js/modules/es.string.split";
import _typeof from "/Users/suifeng/Desktop/projects/2024/fahuo/thFaHuoAdmin/node_modules/@babel/runtime/helpers/esm/typeof";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import upload from '@/plugin/upload';
import d2IconSelect from '@/components/d2-icon-select';
import setting from '@/setting';
export default {
  components: {
    d2IconSelect: d2IconSelect
  },
  props: {
    /***
     * 字段模型
     * [{"defaultValue":"","required":1,"label":"项⽬地址","type":"text",desc:"","prop":"projectDetailedAddress",exProps:{}}]
     * exProps:{
      activeValue:1,//switchProps
      inactiveValue:0,//switchProps
      checkSize:0,//fileProps是否检测尺寸
      fileType:".png,.jpg,.jpeg",//fileProps
      height:320,//fileProps
      width:640,//fileProps
      maxNum:1,//fileProps最多上传数量
      maxSize:256,//fileProps最大上传尺寸 单位kb
      remote:false,//数据函数返回
      remoteFunc:''
      multiple:0,//是否多选
      data:[{key:'',value:'',children:[]}],//selectProps、cascaderProps数据源
      relation:true,//自身改变后是否更新其他字段
      relationProps:{
        {
          prop:prop1//被影响的字段需要调用自己的关联函数更新
        },
        {
          prop:prop2,//被影响的字段需要调用自己的关联函数更新
          remoteProp:'prop'//变化后需要更新的字段 prop的值付给prop2的值
        }
      }
    },
     **/
    formModel: {
      type: Array,
      required: true
    },
    //字段模型 [{"defaultValue":"","required":1,"label":"项⽬地址","type":"text","prop":"projectDetailedAddress",desc:"",exProps}]
    remoteFunc: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      form: {},
      isFilePlusShow: {}
    };
  },
  watch: {},
  computed: {
    rules: function rules() {
      var tmp = {};

      for (var i in this.formModel) {
        var item = this.formModel[i];
        var newProp = this.separatorProp(item.prop);
        tmp[newProp] = [];

        if (item.required) {
          tmp[newProp].push({
            required: item.required,
            message: '请输入' + item.label,
            trigger: 'change'
          });
        }

        var desc = '';

        for (var _i in item.rules) {
          var rule = item.rules[_i];
          tmp[newProp].push({
            validator: this.$tempModel.rules[rule.name].validator,
            trigger: 'blur'
          });
          desc += this.$tempModel.rules[rule.name].desc + ' ';
        }

        if (item.min && item.min !== '' && item.max && item.max !== '') {
          var msg = "长度在 " + item.min + " 到 " + item.max + " 个字符";
          tmp[newProp].push({
            min: parseInt(item.min),
            max: parseInt(item.max),
            message: msg,
            trigger: "blur"
          });
          desc += msg + ' ';
        } else {
          if (item.min && item.min !== '') {
            var _msg = "最少 " + item.min + " 个字符";

            tmp[newProp].push({
              min: parseInt(item.min),
              message: _msg,
              trigger: "blur"
            });
            desc += _msg + ' ';
          } else if (item.max && item.max !== '') {
            var _msg2 = "最多 " + item.max + " 个字符";

            tmp[newProp].push({
              max: parseInt(item.max),
              message: _msg2,
              trigger: "blur"
            });
            desc += _msg2 + ' ';
          }
        }

        if (desc.length > 0) {
          desc = desc.substr(0, desc.length - 1);
        }

        if (!item.desc || item.desc === '') {
          item.desc = desc;
        }
      }

      return tmp;
    },
    labelWidth: function labelWidth() {
      var maxNum = 0;

      for (var i in this.formModel) {
        var label = this.formModel[i].label;
        var wordNum = label.length;

        if (wordNum > maxNum) {
          maxNum = wordNum;
        }
      }

      if (maxNum > 15) {
        return '200px';
      } else if (maxNum > 5) {
        return '160px';
      } else {
        return '100px';
      }
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    clearForm: function clearForm() {
      var _this = this;

      this.$nextTick(function () {
        _this.$refs.form.clearValidate();
      });
    },
    submitForm: function submitForm(next) {
      var _this2 = this;

      this.$refs['form'].validate(function (valid) {
        if (next) {
          for (var key in _this2.form) {
            var ary = key.split('-');

            if (ary.length === 2) {
              _this2.form[ary[0]] = {};
              _this2.form[ary[0]][ary[1]] = _this2.form[key];
            } else if (ary.length === 3) {
              _this2.form[ary[0]] = {};
              _this2.form[ary[0]][ary[1]] = {};
              _this2.form[ary[0]][ary[1]][ary[2]] = _this2.form[key];
            }
          }

          next(valid, _this2.form);
        }
      });
    },
    reloadForm: function reloadForm(editData) {
      var _this3 = this;

      var tmp = {};

      for (var i in this.formModel) {
        var item = this.formModel[i];
        var newProp = this.separatorProp(item.prop); //处理 TUser.name多级字段

        if (editData && editData.hasOwnProperty(item.prop)) {
          tmp[newProp] = editData[item.prop];
        } else {
          if (item.hasOwnProperty('defaultValue')) {
            if (item.type === this.$tempModel.showType.rate) {
              tmp[newProp] = item.defaultValue === '' ? 0 : parseInt(item.defaultValue + '');
            } else {
              tmp[newProp] = item.defaultValue;
            }
          } else {
            tmp[newProp] = '';

            if (item.type === this.$tempModel.showType.select) {
              if (item.exProps.multiple) {
                tmp[newProp] = [];
              }
            }
          }
        }

        if (item.type === this.$tempModel.showType.image || item.type === this.$tempModel.showType.avatar || item.type === this.$tempModel.showType.video || item.type === this.$tempModel.showType.audio) {
          //图片、文件赋值
          this.isFilePlusShow[item.prop] = true;
          tmp[newProp + '_files'] = [];
          var fileUrl = tmp[newProp];

          if (fileUrl && fileUrl !== '') {
            tmp[newProp + '_files'].push({
              name: fileUrl,
              url: fileUrl
            });
            this.isFilePlusShow[item.prop] = false;
          }
        } else if (item.type === this.$tempModel.showType.images) {
          //图片、文件赋值
          var tmpUrls = [];
          var fileUrls = tmp[newProp];

          for (var j in fileUrls) {
            tmpUrls.push({
              name: fileUrls[j],
              url: fileUrls[j]
            });
          }

          this.isFilePlusShow[item.prop] = true;

          if (tmpUrls.length >= item.maxNum) {
            this.isFilePlusShow[item.prop] = false;
          }

          tmp[newProp + '_files'] = tmpUrls;
        }
      }

      this.form = tmp;
      this.callRemoteFuncs();
      this.$nextTick(function () {
        _this3.$refs.form.clearValidate();
      });
    },
    callRemoteFuncs: function callRemoteFuncs() {
      var _this4 = this;

      var _loop = function _loop(i) {
        var item = _this4.formModel[i];

        if (item.exProps.remote) {
          //通过条用函数获取数据
          var funcName = item.exProps.remoteFunc;

          if (_this4.remoteFunc[funcName]) {
            var relationProp = _this4.whoRelationMe(item.prop); //谁变更会影响我


            if (relationProp) {
              //存在
              var relationValue = _this4.form[_this4.separatorProp(relationProp)];

              if (relationValue && relationValue != '') {
                //会影响我的字段已有值 如select已经选了值
                _this4.remoteFunc[funcName](relationProp, relationValue, function (tag, data) {
                  item.exProps.data = data;
                });
              }
            } else {
              _this4.remoteFunc[funcName](item.prop, null, function (tag, data) {
                item.exProps.data = data;
              });
            }
          }
        }
      };

      for (var i in this.formModel) {
        _loop(i);
      }
    },
    whoRelationMe: function whoRelationMe(prop) {
      for (var i in this.formModel) {
        var item = this.formModel[i];

        if (!item.exProps) {
          continue;
        }

        if (item.exProps.relation) {
          for (var j in item.exProps.relationProps) {
            if (item.exProps.relationProps[j].prop == prop) {
              return item.prop;
            }
          }
        }
      }

      return null;
    },
    separatorProp: function separatorProp(prop) {
      return prop.replace('.', setting.fieldSeparator);
    },
    updateFilePlusShow: function updateFilePlusShow(prop, value) {
      var tmp = this.$tempModel.deepCopy(this.isFilePlusShow);
      tmp[prop] = value; //单个上传控制上传按钮隐藏

      this.isFilePlusShow = tmp;
    },
    onBeforeUpload: function onBeforeUpload(file, item) {
      var size = file.size;

      if (size > item.maxSize * 1024) {
        this.$message.error('文件大于' + item.exProps.maxSize + 'kb');
        return false;
      }

      var extensionName = file.name.substr(file.name.lastIndexOf('.')); // 文件扩展名

      if (item.exProps.fileType.indexOf(extensionName) < 0) {
        this.$message.error('文件类型不正确');
        return false;
      }

      return true;
    },
    onUpload: function onUpload(option, exData) {
      if (exData) {
        upload.upload(option, exData);
      } else {
        upload.upload(option);
      }
    },
    onSuccess: function onSuccess(response, file, fileList, item) {
      var pathList = [];
      var newProp = this.separatorProp(item.prop);

      if (this.form[newProp] && _typeof(this.form[newProp]) === 'object') {
        pathList = this.$tempModel.deepCopy(this.form[newProp]);
      }

      pathList.push(response.url);

      if (item.exProps.maxNum === 1) {
        this.updateFilePlusShow(item.prop, false);
        this.form[newProp] = pathList[0];
      } else {
        if (pathList.length >= item.maxNum) {
          this.updateFilePlusShow(item.prop, false);
        }

        this.form[newProp] = pathList;
      }
    },
    onRemove: function onRemove(file, fileList, item) {
      var pathList = [];

      for (var i in fileList) {
        pathList.push(fileList[i].url);
      }

      var newProp = this.separatorProp(item.prop);

      if (item.exProps.maxNum === 1) {
        this.updateFilePlusShow(item.prop, true);
        this.form[newProp] = '';
      } else {
        this.updateFilePlusShow(item.prop, true);
        this.form[newProp] = pathList;
      }
    },
    onError: function onError(err, file, fileList, item) {
      this.$message.error(err.msg);
    },
    formModelIndexByProp: function formModelIndexByProp(prop) {
      var index = -1;

      for (var i in this.formModel) {
        if (this.formModel[i].prop === prop) {
          index = i;
          break;
        }
      }

      return index;
    },
    isRemote: function isRemote(prop) {
      var index = this.formModelIndexByProp(prop);

      if (index === -1) {
        return false;
      }

      var exProps = this.formModel[index].exProps;

      if (exProps && exProps.remote) {
        //是函数形式
        return true;
      }

      return false;
    },
    remoteFunCall: function remoteFunCall(prop) {
      var _this5 = this;

      var relationData = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var index = this.formModelIndexByProp(prop);
      var exProps = this.formModel[index].exProps;

      if (exProps && exProps.remote) {
        //是函数形式
        if (this.remoteFunc[exProps.remoteFunc]) {
          this.remoteFunc[exProps.remoteFunc](prop, relationData, function (tag, data) {
            exProps.data = data;

            if (relationData) {
              _this5.form[prop] = '';
            }
          });
        }
      }
    },
    relationCall: function relationCall(prop, relationData) {
      var index = this.formModelIndexByProp(prop);
      var exProps = this.formModel[index].exProps;

      if (exProps && exProps.relation) {
        //变更时否更新其他字段
        for (var i in exProps.relationProps) {
          var targetData = exProps.relationProps[i];
          var targetProp = targetData.prop;

          if (this.isRemote(targetProp)) {
            //更新对应函数
            this.remoteFunCall(targetProp, relationData);
          }

          if (!exProps.data) {
            //没有绑定数据不需要进行其他字段更新处理
            return;
          }

          var data = {};

          for (var _i2 in exProps.data) {
            //获取当前选择的数据行
            if (exProps.data[_i2].key == relationData) {
              data = exProps.data[_i2];
              break;
            }
          }

          this.form[this.separatorProp(targetProp)] = data[targetData.remoteProp];
        }
      }
    },
    onCascaderChange: function onCascaderChange(val, prop) {
      this.relationCall(prop, val);
    },
    onSwitchChange: function onSwitchChange(val, prop) {
      this.relationCall(prop, val);
    },
    onSelectChange: function onSelectChange(val, prop) {
      this.relationCall(prop, val);
    }
  }
};