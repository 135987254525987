import "core-js/modules/es.array.map";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.search";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from "@/components/rw/tempTable/index";
import tempSearch from "@/components/rw/tempSearch/index";
import tempForm from "@/components/rw/tempForm/index";
import tempChart from "@/components/rw/tempChart/index";
import { balanceCompanyList, balanceCompanyAdd, balanceCompanyDel, balanceCompanySign, balanceCompanySync, balanceCompanyInit, areaList } from '@/api/apis';
import takeMoney from '@/views/pages/balanceCompany/company/takeMoney.vue';
import takeMsg from '@/views/pages/balanceCompany/company/takeMsg.vue';
export default {
  components: {
    tempTable: tempTable,
    tempSearch: tempSearch,
    tempForm: tempForm,
    tempChart: tempChart,
    takeMoney: takeMoney,
    takeMsg: takeMsg
  },
  name: 'company',
  data: function data() {
    var _this = this;

    return {
      tableModel_1000: {
        "border": true,
        "del": false,
        "headerButton": [{
          "id": "",
          "tag": "add",
          "title": "添加",
          "type": "primary"
        }],
        "id": "id_1000",
        "isGroup": false,
        "isTree": false,
        "rowButton": [{
          "id": "",
          "tag": "edit",
          "title": "编辑",
          "type": "primary"
        }, {
          "id": "",
          "tag": "del",
          "title": "删除",
          "type": "danger"
        }, {
          "icon": "",
          "id": "",
          "tag": "default",
          "title": "初始化帐户",
          "type": null
        }, {
          "icon": "",
          "id": "",
          "tag": "default",
          "title": "短信校验",
          "type": null
        }, {
          "icon": "",
          "id": "",
          "tag": "default",
          "title": "同步企业信息",
          "type": null
        }, {
          "icon": "",
          "id": "",
          "tag": "default",
          "title": "提现签约",
          "type": null
        }, {
          "icon": "",
          "id": "",
          "tag": "default",
          "title": "提现",
          "type": "primary"
        }],
        "showPagination": false,
        "showSelection": false,
        "showSummary": false,
        "stripe": false,
        "tableAutocompleteProps": {},
        "tableCascaderProps": {
          "address": {
            "data": [],
            "id": 0,
            "multiple": 0,
            "remote": true,
            "remoteFunc": "tableFunc_cascader_address"
          }
        },
        "tableFileProps": {},
        "tableModel": [{
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "ID",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "50",
          "prop": "id"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "状态",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": "80",
          "required": 1,
          "prop": "status"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "公司名称",
          "required": 1,
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "150",
          "prop": "company_name"
        }, {
          "add": 1,
          "desc": "0-100",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "分账比例",
          "required": 1,
          "rules": [{
            "name": "decimal"
          }],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "ratio"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "通联账户号",
          "required": 1,
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "150",
          "prop": "biz_user_id"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "企业对公账户",
          "required": 1,
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "150",
          "prop": "account_no"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "电话",
          "required": 1,
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "100",
          "prop": "phone"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "开户行",
          "required": 1,
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "parent_bank_name"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "支付行号",
          "required": 1,
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "union_bank"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "所在地区",
          "required": 1,
          "rules": [],
          "show": 0,
          "type": "cascader",
          "width": null,
          "prop": "address",
          "data": {
            "data": [],
            "id": 0,
            "key": "address",
            "multiple": 0,
            "remote": true
          }
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "所在省",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "province"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "所在市",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "city"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "营业执照号",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "uni_credit"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "账户类型",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": null,
          "prop": "bank_card_pro"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "开户支行",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "bank_name"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "行业代码",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "industry_code"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "行业名称",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "industry_name"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "法人姓名",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "legal_name"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "法人证件号码",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "legal_ids"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "全部余额",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "all_amount"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "冻结余额",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "freezen_amount"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "充值冻结",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "deposit_freezen_amount"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "更新日期",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "160",
          "prop": "balance_update_time"
        }],
        "tableName": "",
        "tableRadioProps": {},
        "tableSelectProps": {
          "bank_card_pro": {
            "data": [{
              "key": 0,
              "value": "个人银行卡"
            }, {
              "key": 1,
              "value": "企业对公账户"
            }],
            "id": 1,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          },
          "status": {
            "data": [{
              "key": 0,
              "value": "未开启"
            }, {
              "key": 1,
              "value": "已开启"
            }],
            "id": 2,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          }
        },
        "tableSwitchProps": {},
        "title": "结算公司",
        "type": "tableTemplate"
      },
      remoteFunc: {
        tableFunc_cascader_address: function tableFunc_cascader_address(tag, relationData, resolve) {
          //筛选项模型数组
          areaList({}).then(function _callee(res) {
            var i, data;
            return regeneratorRuntime.async(function _callee$(_context) {
              while (1) {
                switch (_context.prev = _context.next) {
                  case 0:
                    for (i in res) {
                      _this.loopArea(res[i]);
                    }

                    data = res;
                    resolve(tag, data);

                  case 3:
                  case "end":
                    return _context.stop();
                }
              }
            });
          });
        }
      }
    };
  },
  mounted: function mounted() {
    this.table = this.$refs.ref_1000;
  },
  methods: {
    loopArea: function loopArea(dic) {
      dic.key = dic.name;
      dic.value = dic.name;

      if (dic.layer >= 1) {
        dic.children = null;
        return;
      }

      for (var i in dic.children) {
        this.loopArea(dic.children[i]);
      }
    },
    reqTableData: function reqTableData(params, resolve) {
      var where = {};

      if (this.search) {
        where = this.search.parseWhere();
        params.where = where;
      }

      balanceCompanyList(params).then(function _callee2(res) {
        var i, row;
        return regeneratorRuntime.async(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                for (i in res.rows) {
                  row = res.rows[i];
                  row.address = [row.province, row.city];
                }

                resolve({
                  total: 0,
                  data: res.rows
                });

              case 2:
              case "end":
                return _context2.stop();
            }
          }
        });
      });
    },
    reqAdd: function reqAdd(isEdit, params, row, resolve) {
      if (isEdit) {
        params.id = row.id;
      }

      params.province = params.address[0];
      params.city = params.address[1];
      balanceCompanyAdd(params).then(function _callee3(res) {
        return regeneratorRuntime.async(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                resolve();

              case 1:
              case "end":
                return _context3.stop();
            }
          }
        });
      });
    },
    reqDel: function reqDel(params, resolve) {
      var ids = params.map(function (item) {
        return item.id;
      });
      balanceCompanyDel({
        id: ids
      }).then(function _callee4(res) {
        return regeneratorRuntime.async(function _callee4$(_context4) {
          while (1) {
            switch (_context4.prev = _context4.next) {
              case 0:
                resolve();

              case 1:
              case "end":
                return _context4.stop();
            }
          }
        });
      });
    },
    onClickLink: function onClickLink(prop, row) {//点击列表link类型回调
    },
    onClickRowButton: function onClickRowButton(btnIndex, row) {
      var _this2 = this;

      //点击列表按钮回调
      if (btnIndex === 2) {
        //初始化账户
        this.$g.fun.startLoading();
        balanceCompanyInit({
          id: row.id
        }).then(function _callee5(res) {
          return regeneratorRuntime.async(function _callee5$(_context5) {
            while (1) {
              switch (_context5.prev = _context5.next) {
                case 0:
                  _this2.$message({
                    message: '操作成功',
                    type: 'success'
                  });

                  _this2.table.reloadData();

                  _this2.$g.fun.endLoading();

                case 3:
                case "end":
                  return _context5.stop();
              }
            }
          });
        }, function () {
          _this2.$g.fun.endLoading();
        });
      } else if (btnIndex === 3) {
        //短信验证
        this.$refs.takeMsg.show(row, function () {
          _this2.table.reloadData();
        });
      } else if (btnIndex === 4) {
        //同步企业信息
        this.$g.fun.startLoading();
        balanceCompanySync({
          id: row.id
        }).then(function _callee6(res) {
          return regeneratorRuntime.async(function _callee6$(_context6) {
            while (1) {
              switch (_context6.prev = _context6.next) {
                case 0:
                  _this2.$message({
                    message: '操作成功',
                    type: 'success'
                  });

                  _this2.$g.fun.endLoading();

                  _this2.table.reloadData();

                case 3:
                case "end":
                  return _context6.stop();
              }
            }
          });
        }, function () {
          _this2.$g.fun.endLoading();
        });
      } else if (btnIndex === 5) {
        this.$g.fun.startLoading();
        balanceCompanySign({
          id: row.id
        }).then(function _callee7(res) {
          return regeneratorRuntime.async(function _callee7$(_context7) {
            while (1) {
              switch (_context7.prev = _context7.next) {
                case 0:
                  window.open(res.url);

                  _this2.$g.fun.endLoading();

                case 2:
                case "end":
                  return _context7.stop();
              }
            }
          });
        }, function () {
          _this2.$g.fun.endLoading();
        });
      }

      if (btnIndex === 6) {
        //提现
        this.$refs.takeMoney.show(row, function () {
          _this2.table.reloadData();
        });
      }
    },
    onClickHeaderButton: function onClickHeaderButton(btnIndex, row) {//点击列表头部按钮回调
    },
    onSummary: function onSummary(param) {//列表尾部合计回调
    },
    onSwitchChange: function onSwitchChange(val, prop, row) {//列表中switch变化事件
    },
    onClickSearchOperate: function onClickSearchOperate(btnIndex, btnData) {
      //条件菜单按钮点击事件
      if (btnData.tag === this.$tempModel.buttonTag.search) {
        this.table.reloadData();
      } else if (btnData.tag === this.$tempModel.buttonTag.export) {
        this.table.exportExcel();
      } else if (btnData.tag === this.$tempModel.buttonTag.import) {
        var header = btnData.header;
        var results = btnData.results;
      }
    },
    onConditionChange: function onConditionChange(tag, index) {
      this.table.reloadData();
    },
    onRowButtonShow: function onRowButtonShow(btnIndex, row) {
      return true;
    }
  }
};