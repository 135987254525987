import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.find";
import "core-js/modules/es.array.find-index";
import "core-js/modules/es.array.map";
import "core-js/modules/es.array.splice";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.parse-int";
import "core-js/modules/web.dom-collections.for-each";
import "core-js/modules/web.dom-collections.iterator";
import _toConsumableArray from "/Users/suifeng/Desktop/projects/2024/fahuo/thFaHuoAdmin/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import "regenerator-runtime/runtime";
import { get } from 'lodash';
import router from '@/router';
import setting from '@/setting.js'; // 判定是否需要缓存

var isKeepAlive = function isKeepAlive(data) {
  return get(data, 'meta.cache', false);
};

export default {
  namespaced: true,
  state: {
    // 可以在多页 tab 模式下显示的页面
    pool: [],
    // 当前显示的多页面列表
    opened: setting.page.opened,
    // 已经加载多标签页数据 https://github.com/d2-projects/d2-admin/issues/201
    openedLoaded: false,
    // 当前页面
    current: '',
    // 需要缓存的页面 name
    keepAlive: []
  },
  actions: {
    initRouter: function initRouter(_ref, routes) {
      var state = _ref.state,
          commit = _ref.commit,
          dispatch = _ref.dispatch;
      commit('init', routes);
    },

    /**
     * @description 确认已经加载多标签页数据 https://github.com/d2-projects/d2-admin/issues/201
     * @param {Object} context
     */
    isLoaded: function isLoaded(_ref2) {
      var state = _ref2.state;
      if (state.openedLoaded) return Promise.resolve();
      return new Promise(function (resolve) {
        var timer = setInterval(function () {
          if (state.openedLoaded) {
            resolve(clearInterval(timer));
          }
        }, 10);
      });
    },

    /**
     * @class opened
     * @description 从持久化数据载入标签页列表
     * @param {Object} context
     */
    openedLoad: function openedLoad(_ref3) {
      var state = _ref3.state,
          commit = _ref3.commit,
          dispatch = _ref3.dispatch;
      return new Promise(function _callee(resolve) {
        var value, i, item, valid;
        return regeneratorRuntime.async(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _context.next = 2;
                return regeneratorRuntime.awrap(dispatch('d2admin/db/get', {
                  dbName: 'sys',
                  path: 'page.opened',
                  defaultValue: setting.page.opened,
                  user: true
                }, {
                  root: true
                }));

              case 2:
                value = _context.sent;
                _context.t0 = regeneratorRuntime.keys(value);

              case 4:
                if ((_context.t1 = _context.t0()).done) {
                  _context.next = 12;
                  break;
                }

                i = _context.t1.value;
                item = value[i];

                if (!(item.name === 'index' && item.fullPath === '/login')) {
                  _context.next = 10;
                  break;
                }

                value.splice(parseInt(i), 1);
                return _context.abrupt("break", 12);

              case 10:
                _context.next = 4;
                break;

              case 12:
                // 在处理函数中进行数据优化 过滤掉现在已经失效的页签或者已经改变了信息的页签
                // 以 fullPath 字段为准
                // 如果页面过多的话可能需要优化算法
                // valid 有效列表 1, 1, 0, 1 => 有效, 有效, 失效, 有效
                valid = []; // 处理数据

                state.opened = value.map(function (opened) {
                  // 忽略首页
                  if (opened.fullPath === '/index') {
                    valid.push(1);
                    return opened;
                  } // 尝试在所有的支持多标签页的页面里找到 name 匹配的页面


                  var find = state.pool.find(function (item) {
                    return item.name === opened.name;
                  }); // 记录有效或无效信息

                  valid.push(find ? 1 : 0); // 返回合并后的数据 新的覆盖旧的
                  // 新的数据中一般不会携带 params 和 query, 所以旧的参数会留存

                  return Object.assign({}, opened, find);
                }).filter(function (opened, index) {
                  return valid[index] === 1;
                }); // 标记已经加载多标签页数据 https://github.com/d2-projects/d2-admin/issues/201

                state.openedLoaded = true; // 根据 opened 数据生成缓存设置

                commit('keepAliveRefresh'); // end

                resolve();

              case 17:
              case "end":
                return _context.stop();
            }
          }
        });
      });
    },

    /**
     * 将 opened 属性赋值并持久化 在这之前请先确保已经更新了 state.opened
     * @param {Object} context
     */
    opened2db: function opened2db(_ref4) {
      var state = _ref4.state,
          dispatch = _ref4.dispatch;
      return new Promise(function _callee2(resolve) {
        return regeneratorRuntime.async(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                // 设置数据
                dispatch('d2admin/db/set', {
                  dbName: 'sys',
                  path: 'page.opened',
                  value: state.opened,
                  user: true
                }, {
                  root: true
                }); // end

                resolve();

              case 2:
              case "end":
                return _context2.stop();
            }
          }
        });
      });
    },

    /**
     * @class opened
     * @description 更新页面列表上的某一项
     * @param {Object} context
     * @param {Object} payload { index, params, query, fullPath } 路由信息
     */
    openedUpdate: function openedUpdate(_ref5, _ref6) {
      var state = _ref5.state,
          commit = _ref5.commit,
          dispatch = _ref5.dispatch;
      var index = _ref6.index,
          params = _ref6.params,
          query = _ref6.query,
          fullPath = _ref6.fullPath;
      return new Promise(function _callee3(resolve) {
        var page;
        return regeneratorRuntime.async(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                // 更新页面列表某一项
                page = state.opened[index];
                page.params = params || page.params;
                page.query = query || page.query;
                page.fullPath = fullPath || page.fullPath;
                state.opened.splice(index, 1, page); // 持久化

                _context3.next = 7;
                return regeneratorRuntime.awrap(dispatch('opened2db'));

              case 7:
                // end
                resolve();

              case 8:
              case "end":
                return _context3.stop();
            }
          }
        });
      });
    },

    /**
     * @class opened
     * @description 重排页面列表上的某一项
     * @param {Object} context
     * @param {Object} payload { oldIndex, newIndex } 位置信息
     */
    openedSort: function openedSort(_ref7, _ref8) {
      var state = _ref7.state,
          commit = _ref7.commit,
          dispatch = _ref7.dispatch;
      var oldIndex = _ref8.oldIndex,
          newIndex = _ref8.newIndex;
      return new Promise(function _callee4(resolve) {
        var page;
        return regeneratorRuntime.async(function _callee4$(_context4) {
          while (1) {
            switch (_context4.prev = _context4.next) {
              case 0:
                // 重排页面列表某一项
                page = state.opened[oldIndex];
                state.opened.splice(oldIndex, 1);
                state.opened.splice(newIndex, 0, page); // 持久化

                _context4.next = 5;
                return regeneratorRuntime.awrap(dispatch('opened2db'));

              case 5:
                // end
                resolve();

              case 6:
              case "end":
                return _context4.stop();
            }
          }
        });
      });
    },

    /**
     * @class opened
     * @description 新增一个 tag (打开一个页面)
     * @param {Object} context
     * @param {Object} payload new tag info
     */
    add: function add(_ref9, _ref10) {
      var state = _ref9.state,
          commit = _ref9.commit,
          dispatch = _ref9.dispatch;
      var tag = _ref10.tag,
          params = _ref10.params,
          query = _ref10.query,
          fullPath = _ref10.fullPath;
      return new Promise(function _callee5(resolve) {
        var newTag;
        return regeneratorRuntime.async(function _callee5$(_context5) {
          while (1) {
            switch (_context5.prev = _context5.next) {
              case 0:
                // 设置新的 tag 在新打开一个以前没打开过的页面时使用
                newTag = tag;
                newTag.params = params || newTag.params;
                newTag.query = query || newTag.query;
                newTag.fullPath = fullPath || newTag.fullPath; // 添加进当前显示的页面数组

                state.opened.push(newTag); // 如果这个页面需要缓存 将其添加到缓存设置

                if (isKeepAlive(newTag)) {
                  commit('keepAlivePush', tag.name);
                } // 持久化


                _context5.next = 8;
                return regeneratorRuntime.awrap(dispatch('opened2db'));

              case 8:
                // end
                resolve();

              case 9:
              case "end":
                return _context5.stop();
            }
          }
        });
      });
    },

    /**
     * @class current
     * @description 打开一个新的页面
     * @param {Object} context
     * @param {Object} payload 从路由钩子的 to 对象上获取 { name, params, query, fullPath } 路由信息
     */
    open: function open(_ref11, _ref12) {
      var state = _ref11.state,
          commit = _ref11.commit,
          dispatch = _ref11.dispatch;
      var name = _ref12.name,
          params = _ref12.params,
          query = _ref12.query,
          fullPath = _ref12.fullPath;
      return new Promise(function _callee6(resolve) {
        var opened, pageOpendIndex, pageOpend, page;
        return regeneratorRuntime.async(function _callee6$(_context6) {
          while (1) {
            switch (_context6.prev = _context6.next) {
              case 0:
                // 已经打开的页面
                opened = state.opened; // 判断此页面是否已经打开 并且记录位置

                pageOpendIndex = 0;
                pageOpend = opened.find(function (page, index) {
                  var same = page.fullPath === fullPath;
                  pageOpendIndex = same ? index : pageOpendIndex;
                  return same;
                });

                if (!pageOpend) {
                  _context6.next = 8;
                  break;
                }

                _context6.next = 6;
                return regeneratorRuntime.awrap(dispatch('openedUpdate', {
                  index: pageOpendIndex,
                  params: params,
                  query: query,
                  fullPath: fullPath
                }));

              case 6:
                _context6.next = 12;
                break;

              case 8:
                // 页面以前没有打开过
                page = state.pool.find(function (t) {
                  return t.name === name;
                }); // 如果这里没有找到 page 代表这个路由虽然在框架内 但是不参与标签页显示

                if (!page) {
                  _context6.next = 12;
                  break;
                }

                _context6.next = 12;
                return regeneratorRuntime.awrap(dispatch('add', {
                  tag: Object.assign({}, page),
                  params: params,
                  query: query,
                  fullPath: fullPath
                }));

              case 12:
                commit('currentSet', fullPath); // end

                resolve();

              case 14:
              case "end":
                return _context6.stop();
            }
          }
        });
      });
    },

    /**
     * @class opened
     * @description 关闭一个 tag (关闭一个页面)
     * @param {Object} context
     * @param {Object} payload { tagName: 要关闭的标签名字 }
     */
    close: function close(_ref13, _ref14) {
      var state = _ref13.state,
          commit = _ref13.commit,
          dispatch = _ref13.dispatch;
      var tagName = _ref14.tagName;
      return new Promise(function _callee7(resolve) {
        var newPage, isCurrent, len, i, index, _newPage, _newPage$name, name, _newPage$params, params, _newPage$query, query, routerObj;

        return regeneratorRuntime.async(function _callee7$(_context7) {
          while (1) {
            switch (_context7.prev = _context7.next) {
              case 0:
                // 下个新的页面
                newPage = state.opened[0];
                isCurrent = state.current === tagName; // 如果关闭的页面就是当前显示的页面

                if (!isCurrent) {
                  _context7.next = 12;
                  break;
                }

                // 去找一个新的页面
                len = state.opened.length;
                i = 1;

              case 5:
                if (!(i < len)) {
                  _context7.next = 12;
                  break;
                }

                if (!(state.opened[i].fullPath === tagName)) {
                  _context7.next = 9;
                  break;
                }

                if (i < len - 1) {
                  newPage = state.opened[i + 1];
                } else {
                  newPage = state.opened[i - 1];
                }

                return _context7.abrupt("break", 12);

              case 9:
                i++;
                _context7.next = 5;
                break;

              case 12:
                // 找到这个页面在已经打开的数据里是第几个
                index = state.opened.findIndex(function (page) {
                  return page.fullPath === tagName;
                });

                if (index >= 0) {
                  // 如果这个页面是缓存的页面 将其在缓存设置中删除
                  commit('keepAliveRemove', state.opened[index].name); // 更新数据 删除关闭的页面

                  state.opened.splice(index, 1);
                } // 持久化


                _context7.next = 16;
                return regeneratorRuntime.awrap(dispatch('opened2db'));

              case 16:
                // 最后需要判断是否需要跳到首页
                if (isCurrent) {
                  _newPage = newPage, _newPage$name = _newPage.name, name = _newPage$name === void 0 ? '' : _newPage$name, _newPage$params = _newPage.params, params = _newPage$params === void 0 ? {} : _newPage$params, _newPage$query = _newPage.query, query = _newPage$query === void 0 ? {} : _newPage$query;
                  routerObj = {
                    name: name,
                    params: params,
                    query: query
                  };
                  router.push(routerObj);
                } // end


                resolve();

              case 18:
              case "end":
                return _context7.stop();
            }
          }
        });
      });
    },

    /**
     * @class opened
     * @description 关闭当前标签左边的标签
     * @param {Object} context
     * @param {Object} payload { pageSelect: 当前选中的tagName }
     */
    closeLeft: function closeLeft(_ref15) {
      var state = _ref15.state,
          commit = _ref15.commit,
          dispatch = _ref15.dispatch;

      var _ref16 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
          pageSelect = _ref16.pageSelect;

      return new Promise(function _callee8(resolve) {
        var pageAim, currentIndex;
        return regeneratorRuntime.async(function _callee8$(_context8) {
          while (1) {
            switch (_context8.prev = _context8.next) {
              case 0:
                pageAim = pageSelect || state.current;
                currentIndex = 0;
                state.opened.forEach(function (page, index) {
                  if (page.fullPath === pageAim) {
                    currentIndex = index;
                  }
                });

                if (currentIndex > 0) {
                  // 删除打开的页面 并在缓存设置中删除
                  state.opened.splice(1, currentIndex - 1).forEach(function (_ref17) {
                    var name = _ref17.name;
                    return commit('keepAliveRemove', name);
                  });
                }

                state.current = pageAim;

                if (router.app.$route.fullPath !== pageAim) {
                  router.push(pageAim);
                } // 持久化


                _context8.next = 8;
                return regeneratorRuntime.awrap(dispatch('opened2db'));

              case 8:
                // end
                resolve();

              case 9:
              case "end":
                return _context8.stop();
            }
          }
        });
      });
    },

    /**
     * @class opened
     * @description 关闭当前标签右边的标签
     * @param {Object} context
     * @param {Object} payload { pageSelect: 当前选中的tagName }
     */
    closeRight: function closeRight(_ref18) {
      var state = _ref18.state,
          commit = _ref18.commit,
          dispatch = _ref18.dispatch;

      var _ref19 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
          pageSelect = _ref19.pageSelect;

      return new Promise(function _callee9(resolve) {
        var pageAim, currentIndex;
        return regeneratorRuntime.async(function _callee9$(_context9) {
          while (1) {
            switch (_context9.prev = _context9.next) {
              case 0:
                pageAim = pageSelect || state.current;
                currentIndex = 0;
                state.opened.forEach(function (page, index) {
                  if (page.fullPath === pageAim) {
                    currentIndex = index;
                  }
                }); // 删除打开的页面 并在缓存设置中删除

                state.opened.splice(currentIndex + 1).forEach(function (_ref20) {
                  var name = _ref20.name;
                  return commit('keepAliveRemove', name);
                }); // 设置当前的页面

                state.current = pageAim;

                if (router.app.$route.fullPath !== pageAim) {
                  router.push(pageAim);
                } // 持久化


                _context9.next = 8;
                return regeneratorRuntime.awrap(dispatch('opened2db'));

              case 8:
                // end
                resolve();

              case 9:
              case "end":
                return _context9.stop();
            }
          }
        });
      });
    },

    /**
     * @class opened
     * @description 关闭当前激活之外的 tag
     * @param {Object} context
     * @param {Object} payload { pageSelect: 当前选中的tagName }
     */
    closeOther: function closeOther(_ref21) {
      var state = _ref21.state,
          commit = _ref21.commit,
          dispatch = _ref21.dispatch;

      var _ref22 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
          pageSelect = _ref22.pageSelect;

      return new Promise(function _callee10(resolve) {
        var pageAim, currentIndex;
        return regeneratorRuntime.async(function _callee10$(_context10) {
          while (1) {
            switch (_context10.prev = _context10.next) {
              case 0:
                pageAim = pageSelect || state.current;
                currentIndex = 0;
                state.opened.forEach(function (page, index) {
                  if (page.fullPath === pageAim) {
                    currentIndex = index;
                  }
                }); // 删除打开的页面数据 并更新缓存设置

                if (currentIndex === 0) {
                  state.opened.splice(1).forEach(function (_ref23) {
                    var name = _ref23.name;
                    return commit('keepAliveRemove', name);
                  });
                } else {
                  state.opened.splice(currentIndex + 1).forEach(function (_ref24) {
                    var name = _ref24.name;
                    return commit('keepAliveRemove', name);
                  });
                  state.opened.splice(1, currentIndex - 1).forEach(function (_ref25) {
                    var name = _ref25.name;
                    return commit('keepAliveRemove', name);
                  });
                } // 设置新的页面


                state.current = pageAim;

                if (router.app.$route.fullPath !== pageAim) {
                  router.push(pageAim);
                } // 持久化


                _context10.next = 8;
                return regeneratorRuntime.awrap(dispatch('opened2db'));

              case 8:
                // end
                resolve();

              case 9:
              case "end":
                return _context10.stop();
            }
          }
        });
      });
    },

    /**
     * @class opened
     * @description 关闭所有 tag
     * @param {Object} context
     */
    closeAll: function closeAll(_ref26) {
      var state = _ref26.state,
          commit = _ref26.commit,
          dispatch = _ref26.dispatch;
      return new Promise(function _callee11(resolve) {
        return regeneratorRuntime.async(function _callee11$(_context11) {
          while (1) {
            switch (_context11.prev = _context11.next) {
              case 0:
                // 删除打开的页面 并在缓存设置中删除
                state.opened.splice(1).forEach(function (_ref27) {
                  var name = _ref27.name;
                  return commit('keepAliveRemove', name);
                }); // 持久化

                _context11.next = 3;
                return regeneratorRuntime.awrap(dispatch('opened2db'));

              case 3:
                // 关闭所有的标签页后需要判断一次现在是不是在首页
                if (router.app.$route.name !== 'index') {
                  router.push({
                    name: 'index'
                  });
                } // end


                resolve();

              case 5:
              case "end":
                return _context11.stop();
            }
          }
        });
      });
    }
  },
  mutations: {
    /**
     * @class keepAlive
     * @description 从已经打开的页面记录中更新需要缓存的页面记录
     * @param {Object} state state
     */
    keepAliveRefresh: function keepAliveRefresh(state) {
      state.keepAlive = state.opened.filter(function (item) {
        return isKeepAlive(item);
      }).map(function (e) {
        return e.name;
      });
    },

    /**
     * @description 删除一个页面的缓存设置
     * @param {Object} state state
     * @param {String} name name
     */
    keepAliveRemove: function keepAliveRemove(state, name) {
      var list = _toConsumableArray(state.keepAlive);

      var index = list.findIndex(function (item) {
        return item === name;
      });

      if (index !== -1) {
        list.splice(index, 1);
        state.keepAlive = list;
      }
    },

    /**
     * @description 增加一个页面的缓存设置
     * @param {Object} state state
     * @param {String} name name
     */
    keepAlivePush: function keepAlivePush(state, name) {
      var keep = _toConsumableArray(state.keepAlive);

      keep.push(name);
      state.keepAlive = keep;
    },

    /**
     * @description 清空页面缓存设置
     * @param {Object} state state
     */
    keepAliveClean: function keepAliveClean(state) {
      state.keepAlive = [];
    },

    /**
     * @class current
     * @description 设置当前激活的页面 fullPath
     * @param {Object} state state
     * @param {String} fullPath new fullPath
     */
    currentSet: function currentSet(state, fullPath) {
      state.current = fullPath;
    },

    /**
     * @class pool
     * @description 保存 pool (候选池)
     * @param {Object} state state
     * @param {Array} routes routes
     */
    init: function init(state, routes) {
      var pool = [];

      var push = function push(routes) {
        routes.forEach(function (route) {
          if (route.children && route.children.length > 0) {
            push(route.children);
          } else {
            if (!route.hidden) {
              var meta = route.meta,
                  name = route.name,
                  path = route.path;
              pool.push({
                meta: meta,
                name: name,
                path: path
              });
            }
          }
        });
      };

      push(routes);
      state.pool = pool;
    }
  }
};