import models from '@/components/rw/models';
export default {
  install: function install(Vue, options) {
    var loading = null;
    Vue.prototype.$tempModel = models;
    Vue.prototype.$g = {
      maxExpectNum: 50,
      //最多可查询期数
      gradeData: [],
      fun: {
        startLoading: function startLoading(msg) {
          loading = Vue.prototype.$loading({
            lock: true,
            text: msg ? msg : '加载中...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
        },
        endLoading: function endLoading() {
          loading.close();
        },
        //深拷贝
        deepCopy: function deepCopy(value) {
          return JSON.parse(JSON.stringify(value));
        },

        /** @param dateNow :Date类
         * @param intervalDays ：间隔天数
         * @param bolPastTime  ：Boolean,判断在参数date之前，还是之后，
         */
        getDateRange: function getDateRange(dateNow, intervalDays, bolPastTime) {
          var oneDayTime = 24 * 60 * 60 * 1000;
          var list = [];
          var lastDay;

          if (bolPastTime == true) {
            lastDay = new Date(dateNow.getTime() - intervalDays * oneDayTime);
            list.push(this.formateDate(lastDay) + ' 00:00:00');

            if (intervalDays == 1) {
              list.push(this.formateDate(dateNow) + ' 00:00:00');
            } else {
              list.push(this.formateDate(dateNow) + ' 23:59:59');
            }
          } else {
            lastDay = new Date(dateNow.getTime() + intervalDays * oneDayTime);
            list.push(this.formateDate(dateNow) + ' 00:00:00');
            list.push(this.formateDate(lastDay) + ' 23:59:59');
          }

          return list;
        },
        formateDate: function formateDate(time) {
          var year = time.getFullYear();
          var month = time.getMonth() + 1;
          var day = time.getDate();

          if (month < 10) {
            month = '0' + month;
          }

          if (day < 10) {
            day = '0' + day;
          }

          return year + '-' + month + '-' + day + '';
        }
      }
    };
  }
};