var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "订单换货",
        visible: _vm.dialogVisible,
        width: "80%",
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "show-close": false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            "max-height": "50vh",
            "overflow-y": "auto",
            padding: "15px 30px",
            "background-color": "#f6f6f6"
          }
        },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { "label-width": "80px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "产品选择" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择", filterable: "" },
                          model: {
                            value: _vm.productCode,
                            callback: function($$v) {
                              _vm.productCode = $$v
                            },
                            expression: "productCode"
                          }
                        },
                        _vm._l(_vm.productList, function(item, index) {
                          return _c("el-option", {
                            key: item.code,
                            attrs: {
                              label: item.code + item.store_name,
                              value: item.code
                            }
                          })
                        }),
                        1
                      ),
                      _c("el-input-number", {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { min: 1 },
                        model: {
                          value: _vm.productNum,
                          callback: function($$v) {
                            _vm.productNum = $$v
                          },
                          expression: "productNum"
                        }
                      }),
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "10px" },
                          on: { click: _vm.addProduct }
                        },
                        [_vm._v("添加")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c(
                    "el-table",
                    {
                      ref: "tableUpdate",
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.tableData }
                    },
                    [
                      _c("el-table-column", {
                        attrs: { prop: "id", label: "产品ID", width: "80" }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "store_name",
                          label: "产品名称",
                          width: "300"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: { label: "产品数量" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("el-input", {
                                  attrs: {
                                    size: "mini",
                                    placeholder: "请输入数量"
                                  },
                                  on: {
                                    input: function(value) {
                                      return _vm.handleNumInput(
                                        value,
                                        scope.row
                                      )
                                    }
                                  },
                                  model: {
                                    value: scope.row.product_num,
                                    callback: function($$v) {
                                      _vm.$set(
                                        scope.row,
                                        "product_num",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression: "scope.row.product_num"
                                  }
                                })
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: { label: "产品价格" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("el-input", {
                                  attrs: {
                                    size: "mini",
                                    placeholder: "请输入价格"
                                  },
                                  on: {
                                    input: function(value) {
                                      return _vm.handlePriceInput(
                                        value,
                                        scope.row
                                      )
                                    }
                                  },
                                  model: {
                                    value: scope.row.price,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "price", _vm._n($$v))
                                    },
                                    expression: "scope.row.price"
                                  }
                                })
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "totalPrice", label: "总价" }
                      }),
                      _c("el-table-column", {
                        attrs: { label: "操作" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("el-button", {
                                  attrs: {
                                    icon: "el-icon-delete",
                                    circle: "",
                                    size: "mini"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.clickDel(
                                        scope.$index,
                                        scope.row
                                      )
                                    }
                                  }
                                })
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "总价" } },
                [
                  _c("el-input", {
                    staticStyle: {
                      width: "200px",
                      "font-size": "20px",
                      "font-weight": "bold"
                    },
                    attrs: { placeholder: "请输入总价格" },
                    on: { input: _vm.handleTotalPriceInput },
                    model: {
                      value: _vm.total_price,
                      callback: function($$v) {
                        _vm.total_price = $$v
                      },
                      expression: "total_price"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.dialogVisible = false
                }
              }
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.clickSubmit } },
            [_vm._v("提交")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }