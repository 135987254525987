import "core-js/modules/es.symbol";
import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.map";
import "core-js/modules/es.object.get-own-property-descriptor";
import "core-js/modules/es.object.get-own-property-descriptors";
import "core-js/modules/es.object.keys";
import "core-js/modules/web.dom-collections.for-each";
import _defineProperty from "/Users/suifeng/Desktop/projects/2024/fahuo/thFaHuoAdmin/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _toConsumableArray from "/Users/suifeng/Desktop/projects/2024/fahuo/thFaHuoAdmin/node_modules/@babel/runtime/helpers/esm/toConsumableArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { throttle } from 'lodash';
import { mapState } from 'vuex';
import menuMixin from '../mixin/menu';
import { elMenuItem, elSubmenu } from '../libs/util.menu';
export default {
  name: 'd2-layout-header-aside-menu-header',
  mixins: [menuMixin],
  render: function render(createElement) {
    var _this = this;

    return createElement('div', {
      attrs: {
        flex: 'cross:center'
      },
      class: {
        'd2-theme-header-menu': true,
        'is-scrollable': this.isScroll
      },
      ref: 'page'
    }, [createElement('div', {
      attrs: {
        class: 'd2-theme-header-menu__content',
        flex: '',
        'flex-box': '1'
      },
      ref: 'content'
    }, [createElement('div', {
      attrs: {
        class: 'd2-theme-header-menu__scroll',
        'flex-box': '0'
      },
      style: {
        transform: "translateX(".concat(this.currentTranslateX, "px)")
      },
      ref: 'scroll'
    }, [createElement('el-menu', {
      props: {
        mode: 'horizontal',
        defaultActive: this.active
      },
      on: {
        select: this.handleMenuSelect
      }
    }, this.header.map(function (menu) {
      return (menu.children === undefined ? elMenuItem : elSubmenu).call(_this, createElement, menu);
    }))])])].concat(_toConsumableArray(this.isScroll ? [createElement('div', {
      attrs: {
        class: 'd2-theme-header-menu__prev',
        flex: 'main:center cross:center',
        'flex-box': '0'
      },
      on: {
        click: function click() {
          return _this.scroll('left');
        }
      }
    }, [createElement('i', {
      attrs: {
        class: 'el-icon-arrow-left'
      }
    })]), createElement('div', {
      attrs: {
        class: 'd2-theme-header-menu__next',
        flex: 'main:center cross:center',
        'flex-box': '0'
      },
      on: {
        click: function click() {
          return _this.scroll('right');
        }
      }
    }, [createElement('i', {
      attrs: {
        class: 'el-icon-arrow-right'
      }
    })])] : [])));
  },
  computed: _objectSpread({}, mapState('d2admin/menu', ['header'])),
  data: function data() {
    return {
      active: '',
      isScroll: false,
      scrollWidth: 0,
      contentWidth: 0,
      currentTranslateX: 0,
      throttledCheckScroll: null
    };
  },
  watch: {
    '$route.matched': {
      handler: function handler(val) {
        this.active = val[val.length - 1].path;
      },
      immediate: true
    }
  },
  methods: {
    scroll: function scroll(direction) {
      if (direction === 'left') {
        // 向右滚动
        this.currentTranslateX = 0;
      } else {
        // 向左滚动
        if (this.contentWidth * 2 - this.currentTranslateX <= this.scrollWidth) {
          this.currentTranslateX -= this.contentWidth;
        } else {
          this.currentTranslateX = this.contentWidth - this.scrollWidth;
        }
      }
    },
    checkScroll: function checkScroll() {
      var _this2 = this;

      var contentWidth = this.$refs.content.clientWidth;
      var scrollWidth = this.$refs.scroll.clientWidth;

      if (this.isScroll) {
        // 页面依旧允许滚动的情况，需要更新width
        if (this.contentWidth - this.scrollWidth === this.currentTranslateX) {
          // currentTranslateX 也需要相应变化【在右端到头的情况时】
          this.currentTranslateX = contentWidth - scrollWidth; // 快速的滑动依旧存在判断和计算时对应的contentWidth变成正数，所以需要限制一下

          if (this.currentTranslateX > 0) {
            this.currentTranslateX = 0;
          }
        } // 更新元素数据


        this.contentWidth = contentWidth;
        this.scrollWidth = scrollWidth; // 判断何时滚动消失: 当scroll > content

        if (contentWidth > scrollWidth) {
          this.isScroll = false;
        }
      } // 判断何时滚动出现: 当scroll < content


      if (!this.isScroll && contentWidth < scrollWidth) {
        this.isScroll = true; // 注意，当isScroll变为true，对应的元素盒子大小会发生变化

        this.$nextTick(function () {
          contentWidth = _this2.$refs.content.clientWidth;
          scrollWidth = _this2.$refs.scroll.clientWidth;
          _this2.contentWidth = contentWidth;
          _this2.scrollWidth = scrollWidth;
          _this2.currentTranslateX = 0;
        });
      }
    }
  },
  mounted: function mounted() {
    // 初始化判断
    // 默认判断父元素和子元素的大小，以确定初始情况是否显示滚动
    window.addEventListener('load', this.checkScroll); // 全局窗口变化监听，判断父元素和子元素的大小，从而控制isScroll的开关

    this.throttledCheckScroll = throttle(this.checkScroll, 300);
    window.addEventListener('resize', this.throttledCheckScroll);
  },
  beforeDestroy: function beforeDestroy() {
    // 取消监听
    window.removeEventListener('resize', this.throttledCheckScroll);
    window.removeEventListener('load', this.checkScroll);
  }
};