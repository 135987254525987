import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.map";
import "core-js/modules/es.array.slice";
import "core-js/modules/es.array.splice";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.replace";
import "core-js/modules/web.dom-collections.for-each";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { productList, productPackage, orderProductUpdate } from '@/api/apis';
export default {
  data: function data() {
    return {
      productList: [],
      productCode: '',
      productNum: 1,
      isOpen: false,
      tableData: [],
      dialogVisible: false,
      total_price: 0
    };
  },
  mounted: function mounted() {},
  methods: {
    handleTotalPriceInput: function handleTotalPriceInput(value) {
      // 允许的格式为数字并最多保留两位小数
      var formattedValue = value.replace(/[^\d.]/g, '') // 去除非数字和小数点的字符
      .replace(/^0+(\d)/, '$1') // 去除多余的前导零，但保留 "0." 形式
      .replace(/^\./, '0.') // 如果第一个字符是 "."，则改为 "0."
      .replace(/(\.\d{2})\d+/, '$1'); // 限制小数点后最多两位
      // 保证只能有一个小数点

      var dotIndex = formattedValue.indexOf('.');

      if (dotIndex !== -1) {
        formattedValue = formattedValue.slice(0, dotIndex + 1) + formattedValue.slice(dotIndex + 1).replace(/\./g, '');
      }

      this.total_price = formattedValue;
    },
    updateTotalPrice: function updateTotalPrice() {
      var total = 0;

      for (var i in this.tableData) {
        total += this.tableData[i].totalPrice;
      }

      this.total_price = total;
    },
    handlePriceInput: function handlePriceInput(value, row) {
      // 允许的格式为数字并最多保留两位小数
      var formattedValue = value.replace(/[^\d.]/g, '') // 去除非数字和小数点的字符
      .replace(/^0+(\d)/, '$1') // 去除多余的前导零，但保留 "0." 形式
      .replace(/^\./, '0.') // 如果第一个字符是 "."，则改为 "0."
      .replace(/(\.\d{2})\d+/, '$1'); // 限制小数点后最多两位
      // 保证只能有一个小数点

      var dotIndex = formattedValue.indexOf('.');

      if (dotIndex !== -1) {
        formattedValue = formattedValue.slice(0, dotIndex + 1) + formattedValue.slice(dotIndex + 1).replace(/\./g, '');
      }

      row.price = formattedValue;
      row.totalPrice = row.price * row.product_num;
      this.updateTotalPrice();
    },
    handleNumInput: function handleNumInput(value, row) {
      row.product_num = value;
      row.totalPrice = row.price * row.product_num;
      this.updateTotalPrice();
    },
    getSummaries: function getSummaries(param) {
      console.log('getSummaries');
      var columns = param.columns,
          data = param.data;
      var sums = [];
      columns.forEach(function (column, index) {
        if (index === 0) {
          sums[index] = '总价';
          return;
        }

        if (column.property != "totalPrice") {
          return;
        }

        var values = data.map(function (item) {
          return Number(item[column.property]);
        });

        if (!values.every(function (value) {
          return isNaN(value);
        })) {
          sums[index] = values.reduce(function (prev, curr) {
            var value = Number(curr);

            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] += ' 元';
        } else {
          sums[index] = 'N/A';
        }
      });
      return sums;
    },
    show: function show(row, finish) {
      var _this = this;

      this.dialogVisible = true;
      this.finish = finish;
      this.row = row;
      var params = {
        page: 1,
        limit: 100000,
        where: {
          is_main: 1
        }
      };
      productList(params).then(function _callee(res) {
        var tmp, i;
        return regeneratorRuntime.async(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _this.productList = res.rows;
                tmp = [];

                for (i in res.rows) {
                  if (_this.productList[i].is_show) {
                    tmp.push(res.rows[i]);
                  }
                }

                _this.productList = tmp;

              case 4:
              case "end":
                return _context.stop();
            }
          }
        });
      });
      this.tableData = [];

      for (var i in row.t_order_packages) {
        var item = row.t_order_packages[i];
        this.tableData.push({
          id: item.ex_package_product.id,
          store_name: item.ex_package_product.store_name,
          product_num: item.quantity,
          price: item.price,
          totalPrice: item.quantity * item.ex_package_product.price // id:item.sub_product

        });
      }

      this.updateTotalPrice();
      this.total_price = row.total_price;
    },
    getParams: function getParams() {
      return this.tableData;
    },
    reloadData: function reloadData(table) {
      this.tableData = table;
    },
    productByCode: function productByCode(code) {
      for (var i in this.productList) {
        if (this.productList[i].code === code) {
          return this.productList[i];
        }
      }

      return null;
    },
    addProduct: function addProduct() {
      var tmp = this.tableData;
      var item = this.productByCode(this.productCode);

      for (var i in tmp) {
        if (tmp[i].code === item.code && tmp[i].store_name === item.store_name) {
          this.$message({
            message: '商品已添加',
            type: 'warning'
          });
          return;
        }
      }

      tmp.push({
        id: item.id,
        store_name: item.store_name,
        product_num: this.productNum,
        price: item.price,
        totalPrice: this.productNum * item.price
      });
      this.tableData = tmp;
      this.updateTotalPrice();
    },
    reload: function reload() {
      this.tableData = [];
    },
    clickDel: function clickDel(index, row) {
      this.tableData.splice(index, 1);
      this.updateTotalPrice();
    },
    clickSubmit: function clickSubmit() {
      var _this2 = this;

      var params = {};
      params.order_id = this.row.order_id;
      params.total_price = this.total_price;
      params.package = [];

      for (var i in this.tableData) {
        var item = this.tableData[i];
        params.package.push({
          package_id: item.id,
          quantity: item.product_num,
          price: item.price
        });
      }

      this.$g.fun.startLoading();
      orderProductUpdate(params).then(function _callee2(res) {
        return regeneratorRuntime.async(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                _this2.$message({
                  message: '配置成功',
                  type: 'success'
                });

                _this2.dialogVisible = false;

                _this2.$g.fun.endLoading();

                _this2.finish();

              case 4:
              case "end":
                return _context2.stop();
            }
          }
        });
      }, function () {
        _this2.$g.fun.endLoading();
      });
    }
  }
};