import "core-js/modules/es.array.map";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.parse-int";
import "core-js/modules/es.string.search";
import "core-js/modules/web.dom-collections.for-each";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from "@/components/rw/tempTable/index";
import tempSearch from "@/components/rw/tempSearch/index";
import productUpdate from '@/views/pages/order/orderManage/productUpdate.vue';
import orderExamine from '@/views/pages/order/orderManage/orderExamine.vue';
import { expressImport, orderAdd, orderCancel, orderList, productList, userList } from '../../../../api/apis';
export default {
  name: 'orderManage',
  components: {
    tempTable: tempTable,
    tempSearch: tempSearch,
    productUpdate: productUpdate,
    orderExamine: orderExamine
  },
  data: function data() {
    var _this = this;

    return {
      total_price: 0,
      total_product_num: 0,
      activeNames: 'search',
      searchModel_1000: {
        "del": false,
        "id": "id_1000",
        "isCollapse": true,
        "operateData": [{
          "icon": "el-icon-search",
          "tag": "search",
          "title": "搜索",
          "type": "primary"
        }, {
          "icon": "el-icon-position",
          "id": "118",
          "tag": "export",
          "title": "导出",
          "type": "primary"
        }],
        "searchData": [{
          "children": [{
            "field": [],
            "title": "全部",
            "type": "button"
          }, {
            "field": [{
              "key": "auth_status",
              "value": "0"
            }],
            "title": "未审核",
            "type": "button"
          }, {
            "field": [{
              "key": "auth_status",
              "value": "1"
            }],
            "title": "已审核",
            "type": "button"
          }, {
            "field": [{
              "key": "auth_status",
              "value": "2"
            }],
            "title": "驳回",
            "total": 0,
            "type": "button"
          }],
          "id": 1,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "select": 0,
          "title": "审核状态",
          "type": 0,
          "remoteFunc": []
        }, {
          "children": [{
            "type": "button",
            "title": "全部",
            "total": 0,
            "field": []
          }, {
            "field": [{
              "key": "paid",
              "value": "1"
            }],
            "title": "已支付",
            "type": "button"
          }, {
            "field": [{
              "key": "paid",
              "value": "0"
            }],
            "title": "未支付",
            "type": "button"
          }],
          "id": 6,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "select": 0,
          "title": "支付状态",
          "type": 0,
          "remoteFunc": []
        }, {
          "children": [{
            "field": [],
            "title": "全部",
            "type": "button"
          }, {
            "field": [],
            "title": "今天",
            "type": "button"
          }, {
            "field": [],
            "title": "昨天",
            "type": "button"
          }, {
            "field": [],
            "title": "最近7天",
            "type": "button"
          }, {
            "field": [],
            "title": "本月",
            "type": "button"
          }, {
            "field": [],
            "title": "本年",
            "type": "button"
          }, {
            "field": [],
            "title": "自定义",
            "type": "custom-datetime-range"
          }],
          "id": 3,
          "relation": false,
          "relationId": "",
          "remote": true,
          "remoteType": 1,
          "select": 0,
          "title": "订单日期",
          "type": 1,
          "remoteFunc": ["modelConditionFunc_3"]
        }, {
          "children": [{
            "field": [{
              "desc": "订单号",
              "key": "order_id",
              "value": ""
            }],
            "operator": ["like"],
            "placeholder": "请输入",
            "type": "input-complex"
          }, {
            "field": [{
              "desc": "店铺姓名",
              "key": "ex_number.real_name"
            }],
            "operator": [],
            "placeholder": "请输入",
            "type": "input-complex"
          }, {
            "field": [{
              "desc": "店铺电话",
              "key": "ex_number.phone"
            }],
            "operator": [],
            "placeholder": "请输入",
            "type": "input-complex"
          }],
          "id": 2,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "select": 0,
          "title": "订单号",
          "type": 2,
          "remoteFunc": []
        }],
        "type": "searchTemplate"
      },
      tableModel_1001: {
        "border": true,
        "del": false,
        "headerButton": [],
        "id": "id_1001",
        "isTree": false,
        "rowButton": [{
          "icon": "",
          "id": "164",
          "tag": "default",
          "title": "换货",
          "type": "primary"
        }, {
          "icon": "",
          "id": "165",
          "tag": "default",
          "title": "撤单",
          "type": "danger"
        }, {
          "icon": "",
          "id": "166",
          "tag": "default",
          "title": "审核",
          "type": "success"
        }],
        "showPagination": true,
        "showSelection": false,
        "showSummary": true,
        "stripe": false,
        "tableAutocompleteProps": {},
        "tableCascaderProps": {},
        "tableModel": [{
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "订单号",
          "required": 0,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "150",
          "prop": "order_id"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "审核状态",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": "80",
          "prop": "auth_status"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "店铺编号",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "100",
          "prop": "number"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "店铺姓名",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "100",
          "prop": "ex_number.real_name"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "店铺电话",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "100",
          "prop": "ex_number.phone"
        }, {
          "add": 0,
          "detail": 0,
          "excel": 0,
          "groupId": "",
          "label": "物流状态",
          "required": 0,
          "rowAddField": "",
          "rules": [],
          "show": 0,
          "type": "select",
          "width": "100",
          "prop": "status"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "套餐信息",
          "rules": [],
          "show": 1,
          "type": "custom",
          "width": "200",
          "prop": "package_info"
        }, {
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "订单金额",
          "required": 0,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "total_price"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "公司名称",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "120",
          "prop": "ex_company.company_name"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "公司银行账号",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "120",
          "prop": "ex_company.bank_account_no"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "公司法人",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "120",
          "prop": "ex_company.legal_idcard_name"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "订单日期",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "150",
          "prop": "add_time"
        }, {
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "支付日期",
          "required": 0,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "150",
          "prop": "pay_time"
        }, {
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "是否支付",
          "required": 0,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": "100",
          "prop": "paid"
        }, {
          "add": 0,
          "detail": 0,
          "excel": 0,
          "groupId": "",
          "label": "收货电话",
          "required": 0,
          "rowAddField": "",
          "rules": [],
          "show": 0,
          "type": "text",
          "width": "100",
          "prop": "user_phone"
        }, {
          "add": 0,
          "desc": "",
          "detail": 0,
          "excel": 0,
          "groupId": "",
          "label": "快递信息",
          "rules": [],
          "show": 0,
          "type": "custom",
          "width": "300",
          "prop": "delivery_info"
        }, {
          "add": 0,
          "desc": "",
          "detail": 0,
          "excel": 0,
          "groupId": "",
          "label": "收货地址",
          "rules": [],
          "show": 0,
          "type": "text",
          "width": "300",
          "prop": "address_detail"
        }, {
          "add": 0,
          "desc": "",
          "detail": 0,
          "excel": 0,
          "groupId": "",
          "label": "省",
          "rules": [],
          "show": 0,
          "type": "text",
          "width": "80",
          "prop": "address_province"
        }, {
          "add": 0,
          "desc": "",
          "detail": 0,
          "excel": 0,
          "groupId": "",
          "label": "市",
          "rules": [],
          "show": 0,
          "type": "text",
          "width": "80",
          "prop": "address_city"
        }, {
          "add": 0,
          "desc": "",
          "detail": 0,
          "excel": 0,
          "groupId": "",
          "label": "区",
          "rules": [],
          "show": 0,
          "type": "text",
          "width": "80",
          "prop": "address_district"
        }, {
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "备注",
          "required": 0,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "120",
          "prop": "remark"
        }, {
          "add": 0,
          "desc": "",
          "detail": 0,
          "excel": 0,
          "groupId": "",
          "label": "是否退款",
          "rules": [],
          "show": 0,
          "type": "select",
          "width": "80",
          "prop": "refund_status"
        }],
        "tableName": "t_order",
        "tableRadioProps": {},
        "tableSelectProps": {
          "status": {
            "data": [{
              "key": -1,
              "value": "申请退款"
            }, {
              "key": -2,
              "value": "退货成功"
            }, {
              "key": 0,
              "value": "未出库"
            }, {
              "key": 1,
              "value": "部分出库"
            }, {
              "key": 2,
              "value": "全部出库"
            }, {
              "key": 3,
              "value": "部分发货"
            }, {
              "key": 4,
              "value": "全部发货"
            }, {
              "key": 5,
              "value": "完成"
            }],
            "id": 1,
            "multiple": 0,
            "relation": false,
            "relationId": "",
            "remote": false
          },
          "refund_status": {
            "data": [{
              "key": 0,
              "value": "未退款"
            }, {
              "key": 1,
              "value": "已退款"
            }],
            "id": 7,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          },
          "auth_status": {
            "data": [{
              "key": 0,
              "value": "未审核"
            }, {
              "key": 1,
              "value": "已审核"
            }, {
              "key": -1,
              "value": "驳回"
            }],
            "id": 8,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          },
          "paid": {
            "multiple": 0,
            "data": [{
              "key": 0,
              "value": "未支付"
            }, {
              "key": 1,
              "value": "已支付"
            }],
            "remote": false,
            "id": 9,
            "relation": false,
            "relationProps": []
          }
        },
        "tableSwitchProps": {},
        "title": "订单",
        "type": "tableTemplate"
      },
      remoteFunc: {
        modelConditionFunc_3: function modelConditionFunc_3(tag, relationData, resolve) {
          //筛选条件数据
          //[[{key:"type",value:"1"}]]
          var data = [];
          data = [[{
            key: "add_time",
            value: []
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 0, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 1, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 7, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 30, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 365, true)
          }], [{
            key: "add_time",
            value: []
          }]];
          resolve(tag, data);
        },
        modelConditionFunc_13: function modelConditionFunc_13(tag, relationData, resolve) {
          //筛选条件数据
          //[[{key:"type",value:"1"}]]
          var data = [];
          data = [[{
            key: "pay_time",
            value: []
          }], [{
            key: "pay_time",
            value: _this.$g.fun.getDateRange(new Date(), 0, true)
          }], [{
            key: "pay_time",
            value: _this.$g.fun.getDateRange(new Date(), 1, true)
          }], [{
            key: "pay_time",
            value: _this.$g.fun.getDateRange(new Date(), 7, true)
          }], [{
            key: "pay_time",
            value: _this.$g.fun.getDateRange(new Date(), 30, true)
          }], [{
            key: "pay_time",
            value: _this.$g.fun.getDateRange(new Date(), 365, true)
          }], [{
            key: "pay_time",
            value: []
          }]];
          resolve(tag, data);
        }
      }
    };
  },
  mounted: function mounted() {
    this.table = this.$refs.ref_1001;
    this.search = this.$refs.ref_1000;
  },
  methods: {
    reqTableData: function reqTableData(params, resolve) {
      var _this2 = this;

      var where = {};

      if (this.search) {
        where = this.search.parseWhere();
        params.where = where;
      }

      orderList(params).then(function _callee(res) {
        var i, orderDetail, orderDelivery, msg, omsg1, omsg2, j, item, store_name, price, _j;

        return regeneratorRuntime.async(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                for (i in res.rows) {
                  orderDetail = res.rows[i]['t_order_packages'];
                  orderDelivery = res.rows[i]['t_order_expresses'];
                  msg = '';
                  omsg1 = '';
                  omsg2 = '';

                  for (j in orderDetail) {
                    item = orderDetail[j];
                    store_name = item.ex_package_product ? item.ex_package_product.store_name : '订单异常';
                    price = item.ex_package_product ? item.ex_package_product.price : '订单异常';
                    msg += '<div>';
                    msg += '<span style="color:red">[' + item.quantity + ']</span>';
                    msg += ' ' + store_name;
                    msg += '</div>';
                    omsg1 += '[' + item.quantity + ']';
                    omsg1 += ' ' + store_name + ' ' + price;
                  }

                  res.rows[i]['package_info'] = msg;
                  msg = '';

                  for (_j in orderDelivery) {
                    msg += '<div>';
                    msg += '<span style="color:red">[' + (parseInt(_j) + 1) + ']</span>';
                    msg += ' ' + orderDelivery[_j].express_code + orderDelivery[_j].express_no + ' ' + orderDelivery[_j].add_time;
                    msg += '</div>';
                    omsg2 += '[' + (parseInt(_j) + 1) + ']';
                    omsg2 += ' ' + orderDelivery[_j].express_code + orderDelivery[_j].express_no + ' ' + orderDelivery[_j].add_time;
                  }

                  res.rows[i]['delivery_info'] = msg;
                  res.rows[i]['package_info_o'] = omsg1;
                  res.rows[i]['delivery_info_o'] = omsg2;
                }

                _this2.total_price = res.total_price;
                _this2.total_product_num = res.total_product_num;
                resolve({
                  total: res.count,
                  data: res.rows
                });

              case 4:
              case "end":
                return _context.stop();
            }
          }
        });
      });
    },
    reqAdd: function reqAdd(isEdit, params, row, resolve) {
      if (isEdit) {
        params.order_id = row.order_id;
      }

      orderAdd(params).then(function _callee2(res) {
        return regeneratorRuntime.async(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                resolve();

              case 1:
              case "end":
                return _context2.stop();
            }
          }
        });
      });
    },
    reqDel: function reqDel(params, resolve) {
      var ids = params.map(function (item) {
        return item.id;
      });
      resolve();
    },
    onClickLink: function onClickLink(prop, row) {//点击列表link类型回调
    },
    onClickRowButton: function onClickRowButton(btnIndex, row) {
      var _this3 = this;

      //点击列表按钮回调
      if (btnIndex === 0) {
        this.$refs["productUpdate"].show(row, function () {
          _this3.table.reloadData();
        });
      } else if (btnIndex === 1) {
        var orders = [row.order_id];
        this.confirm('确定要撤销订单号为"' + orders[0] + '"的订单么？', function () {
          orderCancel({
            order_id: orders[0]
          }).then(function _callee3(res) {
            return regeneratorRuntime.async(function _callee3$(_context3) {
              while (1) {
                switch (_context3.prev = _context3.next) {
                  case 0:
                    _this3.$message({
                      message: '撤单成功',
                      type: 'success'
                    });

                    _this3.table.reloadData();

                  case 2:
                  case "end":
                    return _context3.stop();
                }
              }
            });
          });
        });
      } else if (btnIndex === 2) {
        this.$refs["orderExamine"].show(row, function () {
          _this3.table.reloadData();
        });
      }
    },
    onClickHeaderButton: function onClickHeaderButton(btnIndex, row) {//点击列表头部按钮回调
      // if (row.length <= 0){
      //   this.$message({
      //     message: '请先选择一个订单',
      //     type: 'warning'
      //   });
      //   return
      // }
      // let orders = row.map(item => item.order_id)
      // this.confirm('确定要撤销订单号为"'+orders[0]+'"的订单么？',()=>{
      //   orderCancel({order_id:orders[0]}).then(async res=>{
      //     this.$message({
      //       message: '撤单成功',
      //       type: 'success'
      //     });
      //     this.table.reloadData();
      //   })
      // })
    },
    onSummary: function onSummary(param) {
      //列表尾部合计回调
      var columns = param.columns,
          data = param.data;
      console.log('para', param);
      var sums = [];
      columns.forEach(function (column, index) {
        if (index === 0) {
          sums[index] = '总计';
          return;
        }

        if (column.property === 'total_price' || column.property === 'total_pv') {
          var values = data.map(function (item) {
            return Number(item[column.property]);
          });
          sums[index] = values.reduce(function (prev, curr) {
            var value = Number(curr);

            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
        }
      });
      return sums;
    },
    onSwitchChange: function onSwitchChange(val, prop, row) {//列表中switch变化事件
    },
    onClickSearchOperate: function onClickSearchOperate(btnIndex, btnData) {
      var _this4 = this;

      //条件菜单按钮点击事件
      if (btnData.tag === this.$tempModel.buttonTag.search) {
        this.table.reloadData();
      } else if (btnData.tag === this.$tempModel.buttonTag.export) {
        this.table.exportExcelCustom(function (ret, isAll, columns, data) {
          var tmp = _this4.$tempModel.deepCopy(data);

          for (var i = 0; i < tmp.length; i++) {
            tmp[i]['package_info'] = tmp[i]['package_info_o'];
            tmp[i]['delivery_info'] = tmp[i]['delivery_info_o'];
          }

          _this4.$export.excel({
            columns: columns,
            data: tmp,
            title: '订单列表'
          }).then(function () {
            _this4.$g.fun.endLoading();
          });
        }); // this.table.exportExcel()
      } else if (btnData.tag === this.$tempModel.buttonTag.import) {
        var header = btnData.header;
        var results = btnData.results;
        this.confirm('确定更新表格中' + results.length + '条物流信息么？', function () {
          var params = {};
          var data = [];

          try {
            for (var i in results) {
              var item = results[i];
              data.push({
                order_id: item['订单编号'],
                express_code: item['物流公司'],
                express_no: item['快递编号']
              });
            }

            params.data = data;
            expressImport(params).then(function _callee4(res) {
              return regeneratorRuntime.async(function _callee4$(_context4) {
                while (1) {
                  switch (_context4.prev = _context4.next) {
                    case 0:
                      _this4.$message({
                        message: '物流信息更新成功',
                        type: 'success'
                      });

                      _this4.table.reloadData();

                    case 2:
                    case "end":
                      return _context4.stop();
                  }
                }
              });
            });
          } catch (err) {
            _this4.$message({
              message: '表格格式错误',
              type: 'error'
            });
          }
        });
      }
    },
    onConditionChange: function onConditionChange(tag, index) {
      this.table.reloadData();
    },
    confirm: function confirm(msg, handle) {
      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        if (handle) {
          handle();
        }
      }).catch(function () {});
    },
    onRowButtonShow: function onRowButtonShow(btnIndex, row) {
      if (row.auth_status === 0) {
        return true;
      }

      return false;
    }
  }
};