import "core-js/modules/es.object.to-string";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { balanceCompanySms, balanceCompanySmsCheck, tonglianMoneyQuery } from '../../../../api/apis';
export default {
  data: function data() {
    return {
      tagList: [{
        key: 9,
        value: '绑定手机'
      }, {
        key: 6,
        value: '解绑手机'
      }],
      dialogVisible: false,
      isSending: false,
      btnSendTitle: '',
      form: {
        id: '',
        phone: '',
        code: '',
        code_type: 9
      },
      rules: {
        code: [{
          require: true,
          trigger: 'blur'
        }]
      }
    };
  },
  mounted: function mounted() {},
  methods: {
    show: function show(row, finish) {
      this.dialogVisible = true;
      this.form.id = row.id;
      this.form.phone = row.phone;
      this.finish = finish;
      this.isSending = false;
      this.btnSendTitle = '发送验证码';
    },
    clickSend: function clickSend() {
      this.isSending = true;
      this.btnSendTitle = '发送中';
      balanceCompanySms({
        id: this.form.id,
        code_type: this.form.code_type
      }).then(function _callee(res) {
        return regeneratorRuntime.async(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
              case "end":
                return _context.stop();
            }
          }
        });
      });
    },
    clickSubmit: function clickSubmit() {
      var _this = this;

      this.$refs['form'].validate(function (valid) {
        if (valid) {
          _this.$g.fun.startLoading();

          balanceCompanySmsCheck({
            id: _this.form.id,
            code: _this.form.code,
            code_type: _this.form.code_type
          }).then(function _callee2(res) {
            return regeneratorRuntime.async(function _callee2$(_context2) {
              while (1) {
                switch (_context2.prev = _context2.next) {
                  case 0:
                    _this.$g.fun.endLoading();

                    _this.$message({
                      message: '操作成功',
                      type: 'success'
                    });

                    _this.dialogVisible = false;

                    if (_this.finish) {
                      _this.finish();
                    }

                  case 4:
                  case "end":
                    return _context2.stop();
                }
              }
            });
          }, function () {
            _this.$g.fun.endLoading();
          });
        }
      });
    }
  }
};