import "core-js/modules/es.symbol";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.map";
import "core-js/modules/es.object.get-own-property-descriptor";
import "core-js/modules/es.object.get-own-property-descriptors";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.object.to-string";
import "core-js/modules/web.dom-collections.for-each";
import "regenerator-runtime/runtime";
import _defineProperty from "/Users/suifeng/Desktop/projects/2024/fahuo/thFaHuoAdmin/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { uniqueId } from 'lodash'; // 设置文件

import setting from '@/setting.js';
/**
 * 给菜单数据补充上 path 字段
 * https://github.com/d2-projects/d2-admin/issues/209
 * @param {Array} menu 原始的菜单数据
 */

function supplementMenuPath(menu) {
  return menu.map(function (e) {
    return _objectSpread({}, e, {
      path: e.path || uniqueId('d2-menu-empty-')
    }, e.children ? {
      children: supplementMenuPath(e.children)
    } : {});
  });
}

export default {
  namespaced: true,
  state: {
    // 顶栏菜单
    header: [],
    // 侧栏菜单
    aside: [],
    // 侧边栏收缩
    asideCollapse: setting.menu.asideCollapse,
    // 菜单网络数据
    asideMenuList: [],
    // 路由数据列表
    routerList: [],
    // 权限数据列表
    permissions: []
  },
  actions: {
    /**
     * 设置侧边栏展开或者收缩
     * @param {Object} context
     * @param {Boolean} collapse is collapse
     */
    asideCollapseSet: function asideCollapseSet(_ref, collapse) {
      var state = _ref.state,
          dispatch = _ref.dispatch;
      return new Promise(function _callee(resolve) {
        return regeneratorRuntime.async(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                // store 赋值
                state.asideCollapse = collapse; // 持久化

                _context.next = 3;
                return regeneratorRuntime.awrap(dispatch('d2admin/db/set', {
                  dbName: 'sys',
                  path: 'menu.asideCollapse',
                  value: state.asideCollapse,
                  user: true
                }, {
                  root: true
                }));

              case 3:
                // end
                resolve();

              case 4:
              case "end":
                return _context.stop();
            }
          }
        });
      });
    },

    /**
     * 切换侧边栏展开和收缩
     * @param {Object} context
     */
    asideCollapseToggle: function asideCollapseToggle(_ref2) {
      var state = _ref2.state,
          dispatch = _ref2.dispatch;
      return new Promise(function _callee2(resolve) {
        return regeneratorRuntime.async(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                // store 赋值
                state.asideCollapse = !state.asideCollapse; // 持久化

                _context2.next = 3;
                return regeneratorRuntime.awrap(dispatch('d2admin/db/set', {
                  dbName: 'sys',
                  path: 'menu.asideCollapse',
                  value: state.asideCollapse,
                  user: true
                }, {
                  root: true
                }));

              case 3:
                // end
                resolve();

              case 4:
              case "end":
                return _context2.stop();
            }
          }
        });
      });
    },

    /**
     * 从持久化数据读取侧边栏展开或者收缩
     * @param {Object} context
     */
    asideCollapseLoad: function asideCollapseLoad(_ref3) {
      var state = _ref3.state,
          dispatch = _ref3.dispatch;
      return new Promise(function _callee3(resolve) {
        return regeneratorRuntime.async(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                _context3.next = 2;
                return regeneratorRuntime.awrap(dispatch('d2admin/db/get', {
                  dbName: 'sys',
                  path: 'menu.asideCollapse',
                  defaultValue: setting.menu.asideCollapse,
                  user: true
                }, {
                  root: true
                }));

              case 2:
                state.asideCollapse = _context3.sent;
                // end
                resolve();

              case 4:
              case "end":
                return _context3.stop();
            }
          }
        });
      });
    }
  },
  mutations: {
    /**
     * @description 设置顶栏菜单
     * @param {Object} state state
     * @param {Array} menu menu setting
     */
    headerSet: function headerSet(state, menu) {
      // store 赋值
      state.header = supplementMenuPath(menu);
    },

    /**
     * @description 设置侧边栏菜单
     * @param {Object} state state
     * @param {Array} menu menu setting
     */
    asideSet: function asideSet(state, menu) {
      // store 赋值
      state.aside = supplementMenuPath(menu);
    }
  }
};